import Keycloak, { KeycloakConfig, KeycloakTokenParsed } from 'keycloak-js';
import Vue, {  } from 'vue';
import { IAuth, IUser, IUserGroup, IUserModule } from './types';


// region Состояние
interface IState {
    initialized: boolean;
    onSuccessfulInitAction: (() => void) | null;

    instanceCode: string;

    keycloakToken: string;
    keycloakTokenParsed: KeycloakTokenParsed;
    keycloakIdTokenParsed: KeycloakTokenParsed;

    kotlinAuthReady: boolean;

    user: IUser;
}

const state = Vue.observable<IState>({
    initialized: false,
    onSuccessfulInitAction: null,

    instanceCode: '',

    keycloakToken: '',
    keycloakTokenParsed: (null as unknown as KeycloakTokenParsed),
    keycloakIdTokenParsed: (null as unknown as KeycloakTokenParsed),

    kotlinAuthReady: false,

    user: (null as unknown as IUser),
});
// endregion


// region Утилиты
const authAccess = {
    keycloakDebug: false,
};
(window as unknown as Record<string, unknown>).authAccess = authAccess;

const asyncPingKotlin = async (): Promise<void> => {
    try {
        const response = await fetch(
            '/api/keycloak/ping',
            {
                headers: {
                    'Authorization': `Bearer ${keycloak.token}`
                },
            },
        );
        if (!response.ok) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`Cannot initialize http session in server - response has status ${response.status} ${response.statusText}`);
        }
        state.kotlinAuthReady = true;
    } catch (e) {
        state.kotlinAuthReady = false;
        throw e;
    }
};

const KOTLIN_PING_DELAY__AFTER_SUCCESSFUL_UPDATE = 60_000;
const KOTLIN_PING_DELAY__AFTER_FAILED_UPDATE = 10_000;
let nextKotlinPingTime: number = 0;
const pingKotlin = () => {
    if (nextKotlinPingTime > Date.now()) return;
    nextKotlinPingTime = Number.MAX_SAFE_INTEGER;

    asyncPingKotlin()
        .then(() => {
            nextKotlinPingTime = Date.now() + KOTLIN_PING_DELAY__AFTER_SUCCESSFUL_UPDATE;
        })
        .catch((reason) => {
            nextKotlinPingTime = Date.now() + KOTLIN_PING_DELAY__AFTER_FAILED_UPDATE;
            console.error('Error while updating auth on server', reason);
        });
};

const startKotlinPeriodicalPing = () => { setInterval(pingKotlin, 1_000); };

const fetchJson = async <T>(requestAlias: string, input: RequestInfo, init?: RequestInit): Promise<T> => {
    const response = await fetch(input, init);
    if (!response.ok) {
        console.error('Request failed', response);
        throw new Error(`Request "${requestAlias}" failed - response has status ${response.status} ${response.statusText}`);
    }

    return (await response.json() as T);
};
// endregion


// region Элементы DOM для индикации загрузки (отображается до запуска приложения Vue)
interface IInitialDomChange {
    spinnerVisible?: boolean;
    error?: boolean;
    title?: string | null;
    message?: string | null;
}

interface IInitialDom {
    container: HTMLDivElement;
    spinner: HTMLDivElement;
    title: HTMLDivElement;
    message: HTMLDivElement;

    init(): void;
    update(change: IInitialDomChange): void;
}

let appAwaitTimer: number | undefined;

const waitForAppStarted = (): void => {
    const appDiv = document.querySelector('#app');
    if (!(appDiv instanceof HTMLElement)) return;

    if (appDiv.childElementCount > 0) {
        removeInitialDom();
        clearInterval(appAwaitTimer);
        appAwaitTimer = undefined;
    }
};

let initialDom: IInitialDom | null = ((): IInitialDom => {
    return {
        container: document.createElement('div'),
        spinner: document.createElement('div'),
        title: document.createElement('div'),
        message: document.createElement('div'),

        init() {
            this.container.style.marginTop = '48px';
            this.container.style.marginBottom = '8px';
            this.container.style.textAlign = 'center';

            this.spinner.style.border = '16px solid #f3f3f3';
            this.spinner.style.borderTop = '16px solid #3498db';
            this.spinner.style.borderRadius = '50%';
            this.spinner.style.width = '120px';
            this.spinner.style.height = '120px';
            this.spinner.style.margin = 'auto';
            this.container.appendChild(this.spinner);

            this.title.style.marginTop = '12px';
            this.title.style.marginBottom = '8px';
            this.title.style.textAlign = 'center';
            this.title.style.fontWeight = '900';
            this.container.appendChild(this.title);

            this.message.style.textAlign = 'center';
            this.container.appendChild(this.message);

            document.body.appendChild(this.container);

            this.update({ title: 'Authorization...' });

            // Запуск анимации у initialDom.spinner
            this.spinner.animate(
                [
                    { transform: 'rotate(0)' },
                    { transform: 'rotate(360deg)' },
                ],
                { easing: 'linear', duration: 2_000, iterations: Number.POSITIVE_INFINITY },
            );

            // Ожидание заполнения элемента #app дочерними элементами
            appAwaitTimer = setInterval(waitForAppStarted, 25);
        },

        update(change: IInitialDomChange) {
            if (change.spinnerVisible !== undefined) {
                this.spinner.style.display = (change.spinnerVisible ? '' : 'none');
            }
            if (change.error !== undefined) {
                const color = (change.error ? 'red' : '');
                this.title.style.color = color;
                this.message.style.color = color;
            }
            if (change.title !== undefined) this.title.textContent = change.title;
            if (change.message !== undefined) this.message.textContent = change.message;
        },
    };
})();

const removeInitialDom = () => {
    if (initialDom !== null) {
        initialDom.container.remove();
        initialDom = null;
    }
};
// endregion


// region Адаптер keycloak
// noinspection SpellCheckingInspection
let keycloak = Keycloak({
    url: '',
    realm: 'pavlodar-sic',
    clientId: 'pavlodar-ea',
});

const keycloakInit = async (): Promise<unknown> => new Promise((resolve, reject) => {
    keycloak
        .init({
            onLoad: 'login-required',
            checkLoginIframe: false,
        })
        .then(() => {
            if (!updateTokensFromKeycloak()) throw new Error('Cannot get keycloak auth data');
            resolve();
        })
        .catch((reason) => {
            reject(reason);
        });
});

const updateTokensFromKeycloak = (): boolean => {
    const token = keycloak.token;
    const tokenParsed = keycloak.tokenParsed;
    const idTokenParsed = keycloak.idTokenParsed;
    if ((token === undefined) || (tokenParsed === undefined) || (idTokenParsed === undefined)) return false;

    if (state.keycloakToken !== token) state.keycloakToken = token;
    if (state.keycloakTokenParsed !== tokenParsed) state.keycloakTokenParsed = tokenParsed;
    if (state.keycloakIdTokenParsed !== idTokenParsed) state.keycloakIdTokenParsed = idTokenParsed;

    return true;
};

const startKeycloakTokenCheck = () => {
    const keycloakTokenCheckDelay = 1_000;
    const keycloakTokenExpirationDelay = 60_000;
    const keycloakUpdateMaxTime = 10_000;
    let updatingKeycloakToken = false;
    let keycloakTokenUpdateStart = 0;
    let errorPrintTime = 0;
    const errorPrintDelay = 30_000;
    let expirationPrintTime = 0;
    const expirationPrintDelay = 30_000;

    const checkKeycloakToken = () => {
        const currentDate = new Date();
        const currentTime = currentDate.getTime();

        if (updatingKeycloakToken) {
            if (currentTime - keycloakTokenUpdateStart > keycloakUpdateMaxTime) {
                updatingKeycloakToken = false;
            } else {
                return;
            }
        }

        const tokenParsed = keycloak.tokenParsed;
        if (tokenParsed === undefined) return;

        let expirationTime = tokenParsed.exp;
        if (expirationTime === undefined) {
            if (currentTime - errorPrintTime > errorPrintDelay) {
                errorPrintTime = currentTime;
                console.error(
                    "[service/auth]",
                    "Cannot define expiration of keycloak token"
                );
            }
            return;
        }
        expirationTime = expirationTime * 1000;

        if (currentTime - expirationPrintTime > expirationPrintDelay) {
            if (authAccess.keycloakDebug) {
                // eslint-disable-next-line no-console
                console.info(
                    "[service/auth]",
                    "keycloak token expiration:",
                    new Date(expirationTime),
                    `(${expirationTime} ms)`
                );
            }
            expirationPrintTime = currentTime;
        }

        if (expirationTime - currentTime < keycloakTokenExpirationDelay) {
            keycloakTokenUpdateStart = currentTime;
            updatingKeycloakToken = true;
            keycloak
                .updateToken(keycloakTokenExpirationDelay)
                .then(() => {
                    if (updateTokensFromKeycloak()) {
                        pingKotlin();
                    } else {
                        console.error(
                            "[service/auth]",
                            "Cannot read keycloak tokens after refresh"
                        );
                    }
                })
                .catch(() => {
                    console.error(
                        "[service/auth]",
                        "Failed to refresh the token, or the session has expired"
                    );
                });
        }

        updateTokensFromKeycloak();
    };

    setInterval(checkKeycloakToken, keycloakTokenCheckDelay);
};
// endregion


// region Загрузка данных
const asyncLoad = async () => {
    // region Loading instance code and keycloak settings
    initialDom?.update({ message: 'Loading authorization settings...' });

    let instanceCode = '';
    let keycloakConfig: KeycloakConfig = (null as unknown as KeycloakConfig);


    // Loading instance code, keycloak settings
    await Promise.all([
        // Loading instance code
        (async () => {
            const response = await fetch('/api/instance/code');
            if (!response.ok) throw new Error(`Cannot load server instance code - response has status ${response.status} ${response.statusText}`);
            instanceCode = await response.text();
        })(),

        // Loading keycloak settings
        (async () => {
            keycloakConfig = await fetchJson<KeycloakConfig>('Loading instance realm', '/api/instance/realm');
        })(),
    ]);

    state.instanceCode = instanceCode;
    keycloak = Keycloak(keycloakConfig);
    // endregion


    // region Keycloak initialization (may send browser to login page)
    initialDom?.update({ message: 'Initializing authorization adapter...' });
    await keycloakInit();
    startKeycloakTokenCheck();
    startUserInfoUpdating();
    // endregion


    // region Loading user info, initializing http sessions (now - in kotlin only)
    initialDom?.update({ message: 'Loading user data...' });
    state.user = await asyncLoadUserData();
    await asyncPingKotlin();
    // endregion

    // region Finalization
    nextKotlinPingTime = Date.now() + KOTLIN_PING_DELAY__AFTER_SUCCESSFUL_UPDATE;
    startKotlinPeriodicalPing();
    initialDom?.update({ title: 'Done', message: 'Starting application...' });
    // endregion
    sendHostForTranslate();
};

const asyncLoadUserData = async (): Promise<IUser> => {
    const token = state.keycloakIdTokenParsed;

    const sub = token.sub;
    if (sub === undefined) throw new Error('User ID in keycloak token is not defined');

    let login = '';
    if ('name' in token) login = String(token.name);
    let preferredUsername = '';
    if ('preferred_username' in token) preferredUsername = String(token.preferred_username);
    const roles = (keycloak.realmAccess?.roles ?? []);

    let groups: Array<IUserGroup> = [];
    let modules: Array<IUserModule> = [];

    // Параллельная загрузка групп и модулей
    await Promise.all([
        // Загрузка групп
        (async () => {
            const result = await fetchJson<Array<IUserGroup> | null | undefined>('Loading user groups', `/api-py/get-user-all-groups-level/${token.sub}`);
            groups = (Array.isArray(result) ? result : []);
        })(),

        // Загрузка модулей
        (async () => {
            const result = await fetchJson<Array<IUserModule> | null | undefined>('Loading user modules', `/api-py/user-modules/${token.sub}`)
            modules = (Array.isArray(result) ? result : []);
        })(),
    ]);
    return { login, sub, preferredUsername, roles, groups, modules, modulesLoaded: true };
};

const startLoad = (): void => {
    asyncLoad()
        .then(() => {
            state.initialized = true;
            if (state.onSuccessfulInitAction !== null) state.onSuccessfulInitAction();
        })
        .catch((reason) => {
            console.error('Cannot load auth data', reason);

            initialDom?.update({
                spinnerVisible: false,
                error: true,
                title: 'Cannot load authorization data',
                message: String(reason),
            });
        })
    ;
};

const updateUserInfo = (): void => {
    asyncLoadUserData()
        .then((user) => {
            if (state.user !== user) state.user = user;
        })
        .catch((reason) => {
            console.error('Cannot update user info', reason);
        });
};

const startUserInfoUpdating = (): void => { setInterval(updateUserInfo, 60_000); };
// endregion


const auth: IAuth = {
    get initialized(): boolean { return state.initialized; },
    get instanceCode(): string { return state.instanceCode; },
    get keycloakToken(): string { return state.keycloakToken; },
    get keycloakTokenParsed(): KeycloakTokenParsed { return state.keycloakTokenParsed; },
    get keycloakIdTokenParsed(): KeycloakTokenParsed { return state.keycloakIdTokenParsed; },
    get kotlinAuthReady(): boolean { return state.kotlinAuthReady; },
    get user(): IUser { return state.user; },

    start(next: () => void) {
        if (state.initialized) next();
        else state.onSuccessfulInitAction = next;
    },

    logout() { keycloak.logout(); },

    updatePassword() {
        keycloak.login({
            action: 'UPDATE_PASSWORD',
        });
    },

    removeInitialDom,
};


// region Инициализация
setTimeout(() => {
    // Добавление индикации загрузки
    initialDom?.init();

    // Запуск загрузки данных
    startLoad();
});
// endregion

function sendHostForTranslate() {
    try {
        const params = {
            protocol: window.location.protocol,
            host: window.location.host,
            user_id: state.user.sub
        };
        fetch('/api-py/init-host', {
            method: 'POST',
            body: JSON.stringify(params)
        });
    } catch (e) {
        return e
    } finally {
    }
}

export default auth;
export { IAuth, IUser, IUserGroup, IUserModule };
